import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal'
import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import {
  CAvatar,
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import avatar from '../../../../assets/img/avatar.jpg'
import { RootState } from '../../../../reducers'
import { ResponseStatus, ResponseType } from '../../../../shared/enumeration/ResponseStatus'
import { createIndexes } from '../../../../shared/utils/ultils'
import { AppDispatch } from '../../../../store'
import CustomTableFooter from '../../../shared/CustomTableFooter/CustomTableFooter'
import FilterIcon from '../../../shared/icons/FilterIcon'
import PlusIcon from '../../../shared/icons/PlusIcon'
import SearchIcon from '../../../shared/icons/SearchIcon'
import TrashIcon from '../../../shared/icons/TrashIcon'
import ConfigUpdate from '../ConfigUpdate'
import {
  fetching,
  configSelectors,
  resetEntity,
  setFilterState,
} from '../configManagement.reducer'
import { getEntities, removeEntity, updateEntity } from '../configManagement.api'
import { sortBy } from 'lodash'
import { IConfig } from '@/shared/model/config.model'
import NotifyUpdateSettings from './NotifyUpdateSettings'

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Tên thông báo',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Tình trạng',
    sorter: false,
  },
  {
    key: 'description',
    label: 'Mô tả',
    sorter: false,
  },
  // {
  //   key: 'actions',
  //   label: 'Thao tác',
  //   sorter: false,
  //   _props: { className: 'text-center' },
  // },
]

interface IIndexConfig extends IConfig {
  // index: number
}

const NotifyManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState
  const [createConfig, setCreateConfig] = useState<boolean>(false)
  const [updateConfig, setUpdateConfig] = useState<IConfig | null>(null)

  const handleCreateConfig = () => {
    setCreateConfig(true)
  }

  const handleUpdateConfig = (config: IConfig) => () => {
    setUpdateConfig(config)
  }

  const handleCancelUpdateConfig = (update?: boolean) => {
    setUpdateConfig(null)
  }

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0)
      dispatch(setFilterState({ ...filterState, page: page }))
    }
  }

  const configs = useSelector(configSelectors.selectAll)
  const indexedConfig = createIndexes(configs, filterState)

  const handleUpdateStatus = (config: IConfig) => () => {
    const status =
      config.status === ResponseStatus.ACTIVE
        ? ResponseStatus.INACTIVE
        : ResponseStatus.ACTIVE
    dispatch(fetching())
    dispatch(updateEntity({ ...config, status }))
  }

  useEffect(() => {
    if (updateEntitySuccess && !createConfig && !updateConfig) {
      ToastSuccess('Cập nhật thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching())
      dispatch(
        getEntities({ ...filterState, sortBy: 'order', sortOrder: 'ASC', type: ResponseType.NOTIFY }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess])

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess])

  const [chosenDelete, setChosenDelete] = useState<IConfig | null>(null)

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id.toString()))
      setChosenDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setChosenDelete(null)
  }

  const [keyword, setKeyword] = useState<string>('')

  const onSearch = () => {
    dispatch(getEntities({ ...filterState, keyword }))
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedConfig}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{

              image: (item: IIndexConfig) => (
                <td>
                  <img height={100} src={item.image} />
                </td>
              ),


              status: (item: IIndexConfig) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={item.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(item)}
                      disabled={loading}
                    />
                  </div>
                </td>
              ),
              actions: (item: IIndexConfig) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateConfig(item)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {item.status !== ResponseStatus.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(item)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ''
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createConfig ? (
          <ConfigUpdate visible={createConfig} setVisible={setCreateConfig} />
        ) : (
          ''
        )}
        {updateConfig ? (
          <NotifyUpdateSettings
            visible={Boolean(updateConfig)}
            configObj={updateConfig}
            setVisible={handleCancelUpdateConfig}
          />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá Liên hệ khỏi hệ thống"
          content={
            <>
              Xác nhận xoá Liên hệ{' '}
              <span className="high-light">{chosenDelete.name}</span> khỏi hệ
              thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  )
}

export default NotifyManagement
