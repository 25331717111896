
import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const PopupManagement = React.lazy(() => import('./PopupManagement'));

const PopupManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
        <PopupManagement />
      // </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const PopupsManagementRoutes = () => useRoutes(PopupManagementLayout);
export default PopupsManagementRoutes;
