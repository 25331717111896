
import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const MethodManagement = React.lazy(() => import('./MethodManagement'));

const MethodManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
        <MethodManagement />
      // </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const MethodsManagementRoutes = () => useRoutes(MethodManagementLayout);
export default MethodsManagementRoutes;
