import React from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import NotifyManagement from './components/NotifyManagement'

const ConfigManagement = React.lazy(() => import('./ConfigManagement'))

const ConfigManagementLayout: RouteObject[] = [
  {
    path: '/settings',
    element: <ConfigManagement />,
  },
  {
    path: '/notify',
    element: <NotifyManagement />,
  } ,
  { path: '*', element: <Navigate to="/404" /> },
]

const ConfigsManagementRoutes = () => useRoutes(ConfigManagementLayout)
export default ConfigsManagementRoutes
