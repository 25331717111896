export enum ResponseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const responseStatusArray: ResponseStatus[] = [
  ResponseStatus.ACTIVE,
  ResponseStatus.INACTIVE,
];

export const mapResponseStatus: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'Hoạt động',
  [ResponseStatus.INACTIVE]: 'Không hoạt động',
};

export enum ResponseType {
  SETTINGS = 'SETTINGS',
  NOTIFY = 'NOTIFY'
}

export const responseTypeArray: ResponseType[] = [
  ResponseType.SETTINGS,
  ResponseType.NOTIFY,
];

export const mapResponseType: { [key in ResponseType]: string } = {
  [ResponseType.SETTINGS]: 'Cài đặt chung',
  [ResponseType.NOTIFY]: 'Thông báo',
};

export const mapResponseStatusToColor: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'success',
  [ResponseStatus.INACTIVE]: 'danger',
};



export enum ResponseStatusDraw {
  open = 'open',
  draw = 'drawn',
  closed = 'closed',
}

export const mapResponseStatusToColorDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'success',
  [ResponseStatusDraw.draw]: 'gray',
  [ResponseStatusDraw.closed]: 'danger',
};

export const mapResponseStatusDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'Đã mở',
  [ResponseStatusDraw.draw]: 'Chưa mở',
  [ResponseStatusDraw.closed]: 'Đã đóng',
};


export enum ResponseStatusTransaction {
  pending = 0,
  success = 1,
  error = 2,
}

export const mapResponseStatusToColorTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.success]: 'success',
  [ResponseStatusTransaction.pending]: 'primary',
  [ResponseStatusTransaction.error]: 'danger',
};

export const mapResponseStatusTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.pending]: 'Đang chờ',
  [ResponseStatusTransaction.success]: 'Thành công',
  [ResponseStatusTransaction.error]: 'Thất bại',
};


// export enum typeTransaction {
//   deposit = 1, // Nạp tiền
//   withdraw = 2, // Rút tiền
// }

// export enum statusTransaction {
//   pendding = 0, // Đang chờ
//   success = 1, // Thành công
//   error = 2, // Thất bại
// }



export enum ResponseVotedHistory {
  TANGLEN = 1,
  GIAMBOT = 2,
  SUAMOCCHAU = 3,
  SUACOGAIHALAN = 4,
  SUANUTI = 5,
  SUAGOLD = 6,
  SUALADY = 7,
  SUAMILO = 8,
  SUAANFA = 9,
  SUADAUNANH = 10,
}

export const mapResponseColorVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'Nhập',
  [ResponseVotedHistory.GIAMBOT]: 'Xuất',
  [ResponseVotedHistory.SUAMOCCHAU]: 'Sữa mộc châu',
  [ResponseVotedHistory.SUACOGAIHALAN]: 'Sữa cô gái hà lan',
  [ResponseVotedHistory.SUANUTI]: 'Sữa nutifood',
  [ResponseVotedHistory.SUAGOLD]: 'Sữa optimun gold',
  [ResponseVotedHistory.SUALADY]: 'Sữa dutch lady',
  [ResponseVotedHistory.SUAMILO]: 'Sữa milo',
  [ResponseVotedHistory.SUAANFA]: 'Sữa anfa gold',
  [ResponseVotedHistory.SUADAUNANH]: 'Sữa đậu nành',
};

export const mapResponseVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'Nhập',
  [ResponseVotedHistory.GIAMBOT]: 'Xuất',
  [ResponseVotedHistory.SUAMOCCHAU]: 'Sữa mộc châu',
  [ResponseVotedHistory.SUACOGAIHALAN]: 'Sữa cô gái hà lan',
  [ResponseVotedHistory.SUANUTI]: 'Sữa nutifood',
  [ResponseVotedHistory.SUAGOLD]: 'Sữa optimun gold',
  [ResponseVotedHistory.SUALADY]: 'Sữa dutch lady',
  [ResponseVotedHistory.SUAMILO]: 'Sữa milo',
  [ResponseVotedHistory.SUAANFA]: 'Sữa anfa gold',
  [ResponseVotedHistory.SUADAUNANH]: 'Sữa đậu nành',
};


export enum ResponseVip {
  VIP1 = '1',
  VIP2 = '2',
  VIP3 = '3',
  VIP4 = '4',
  VIP5 = '5',
  VIP6 = '6',
}

export const mapResponseVip: { [key in ResponseVip]: string } = {
  [ResponseVip.VIP1]: 'VIP 1',
  [ResponseVip.VIP2]: 'VIP 2',
  [ResponseVip.VIP3]: 'VIP 3',
  [ResponseVip.VIP4]: 'VIP 4',
  [ResponseVip.VIP5]: 'VIP 5',
  [ResponseVip.VIP6]: 'VIP 6',
};

export const responseVipArray: ResponseVip[] = [
  ResponseVip.VIP1,
  ResponseVip.VIP2,
  ResponseVip.VIP3,
  ResponseVip.VIP4,
  ResponseVip.VIP5,
  ResponseVip.VIP6
];
