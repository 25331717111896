import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CImage,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'
import {
  ResponseStatus,
} from '../../../../shared/enumeration/ResponseStatus'
import { ISelectValue } from '../../../../shared/shared-interfaces'
import SModal from '../../../shared/Modal/SModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { AppDispatch } from '../../../../store'
import { ToastSuccess } from '../../../shared/toast/Toast'
import { fetching, resetEntity } from '../configManagement.reducer'
import { createEntity, updateEntity } from '../configManagement.api'
import CIcon from '@coreui/icons-react'
import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import { handleUploadImage } from '@/shared/utils/ultils'
import { cisCameraPlus } from '@coreui/icons-pro'
import { IConfig, INewConfig } from '@/shared/model/config.model'

interface IConfigUpdateProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  configObj?: IConfig
}

const NotifyUpdateSettings = (props: IConfigUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const { updateEntitySuccess } = initialState
  const { visible, setVisible, configObj } = props
  const handleOnClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false)
      ToastSuccess(configObj ? 'Cập nhậtthành công' : 'Tạo thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  const initialValues: INewConfig = {
    key: '',
    name: '',
    image: '',
    link: '',
    order: 0,
    status: ResponseStatus.ACTIVE,
    fileImage: undefined,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Không được để trống'),
  })

  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === optionValue)
  }

  const handleFileUploadAvatar = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileImage', inputFiles[0])
        setFieldValue('image', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleFileUploadQr = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileQr', inputFiles[0])
        setFieldValue('qr', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleRemappingImageValue = async (value: INewConfig) => {
    if (value.fileImage) {
      const avatarUrlPromises = await handleUploadImage(value.fileImage)
      value.image = avatarUrlPromises
    }
    return value
  }

  console.log(configObj)

  return (
    <SModal
      visible={visible}
      onClose={handleOnClose}
      backdrop="static"
      className="custom-modal"
      size="lg"
    >
      <Formik
        initialValues={configObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (value) => {
          const values = await handleRemappingImageValue(value)
          dispatch(fetching())
          if (configObj) {
            dispatch(updateEntity(values as IConfig))
          } else {
            dispatch(createEntity(values))
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{configObj ? 'Chỉnh sửa Nội Dung Thông Báo' : 'Tạo'}</CModalHeader>
              <CModalBody>
              <CRow className="g-4">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tiêu đề thông báo
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="image"
                      autoComplete="off"
                      value={values.image}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.image && touched.image}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.image && touched.image
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.image}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="g-2 mt-3">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Nội dung thông báo
                    </CFormLabel>
                    <CFormTextarea
                      className="form-height-44"
                      name="link"
                      autoComplete="off"
                      value={values.link}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.link && touched.link}
                      rows={4}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.link && touched.link
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.link}
                    </CFormFeedback>
                  </CCol>
                  <CCol>
                    <div>Các thẻ nội dung</div>
                    <ul className='ps-3'>
                    <li>Thẻ ID người dùng: [ID]</li>
                    <li>Thẻ USERNAME tên người dùng: [USERNAME]</li>
                    </ul>
                  </CCol>
                </CRow>


              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm()
                    setVisible(false)
                  }}
                >
                  Huỷ
                </CButton>
                <CButton
                  className="btn-custom minw-120 primary-500"
                  type="submit"
                >
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  )
}

export default NotifyUpdateSettings
