import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal'
import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import {
  CAvatar,
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import avatar from '../../../../assets/img/avatar.jpg'
import { RootState } from '../../../reducers'
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus'
import { createIndexes } from '../../../shared/utils/ultils'
import { AppDispatch } from '../../../store'
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter'
import FilterIcon from '../../shared/icons/FilterIcon'
import PlusIcon from '../../shared/icons/PlusIcon'
import SearchIcon from '../../shared/icons/SearchIcon'
import TrashIcon from '../../shared/icons/TrashIcon'
import ContactUpdate from './ContactUpdate'
import {
  fetching,
  contactSelectors,
  resetEntity,
  setFilterState,
} from './contactManagement.reducer'
import { getEntities, removeEntity, updateEntity } from './contactManagement.api'
import { sortBy } from 'lodash'
import { IContact } from '@/shared/model/contact.model'

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Tên',
    sorter: false,
  },
  {
    key: 'image',
    label: 'Ảnh',
    sorter: false,
  },
  {
    key: 'description',
    label: 'Mô tả',
    sorter: false,
  },
  {
    key: 'link',
    label: 'Đường dẫn',
    sorter: false,
  },
  {
    key: 'order',
    label: 'Thứ tự',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Tình trạng',
    sorter: false,
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
]

interface IIndexContact extends IContact {
  // index: number
}

const ContactManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.contactReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState
  const [createContact, setCreateContact] = useState<boolean>(false)
  const [updateContact, setUpdateContact] = useState<IContact | null>(null)

  const handleCreateContact = () => {
    setCreateContact(true)
  }

  const handleUpdateContact = (contact: IContact) => () => {
    setUpdateContact(contact)
  }

  const handleCancelUpdateContact = (update?: boolean) => {
    setUpdateContact(null)
  }

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0)
      dispatch(setFilterState({ ...filterState, page: page }))
    }
  }

  const contacts = useSelector(contactSelectors.selectAll)
  const indexedContact = createIndexes(contacts, filterState)

  const handleUpdateStatus = (contact: IContact) => () => {
    const status =
      contact.status === ResponseStatus.ACTIVE
        ? ResponseStatus.INACTIVE
        : ResponseStatus.ACTIVE
    dispatch(fetching())
    dispatch(updateEntity({ ...contact, status }))
  }

  useEffect(() => {
    if (updateEntitySuccess && !createContact && !updateContact) {
      ToastSuccess('Cập nhật thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching())
      dispatch(
        getEntities({ ...filterState, sortBy: 'order', sortOrder: 'ASC' }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess])

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess])

  const [chosenDelete, setChosenDelete] = useState<IContact | null>(null)

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id.toString()))
      setChosenDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setChosenDelete(null)
  }

  const [keyword, setKeyword] = useState<string>('')

  const onSearch = () => {
    dispatch(getEntities({ ...filterState, keyword }))
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className="d-flex">
            {/* <CFormInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ID hoặc Liên hệ"
            />
            <CButton
              style={{ height: 42, whiteSpace: 'nowrap' }}
              className="ms-2 btn-custom btn-sm text-white"
              onClick={onSearch}
            >
              Tìm kiếm
            </CButton> */}
          </div>
          <div>
            <CButton
              className="btn-custom primary-500 btn-sm"
              onClick={handleCreateContact}
            >
              <PlusIcon /> Tạo Liên hệ mới
            </CButton>
          </div>
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput
                placeholder="Tìm kiếm theo tên"
                aria-label="Contactname"
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedContact}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{

              image: (item: IIndexContact) => (
                <td>
                  <img height={100} src={item.image} />
                </td>
              ),


              status: (item: IIndexContact) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={item.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(item)}
                      disabled={loading}
                    />
                  </div>
                </td>
              ),
              actions: (item: IIndexContact) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateContact(item)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {item.status !== ResponseStatus.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(item)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ''
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createContact ? (
          <ContactUpdate visible={createContact} setVisible={setCreateContact} />
        ) : (
          ''
        )}
        {updateContact ? (
          <ContactUpdate
            visible={Boolean(updateContact)}
            contactObj={updateContact}
            setVisible={handleCancelUpdateContact}
          />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá Liên hệ khỏi hệ thống"
          content={
            <>
              Xác nhận xoá Liên hệ{' '}
              <span className="high-light">{chosenDelete.name}</span> khỏi hệ
              thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  )
}

export default ContactManagement
