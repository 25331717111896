import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CImage,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'
import {
  ResponseStatus,
  mapResponseStatus,
  mapResponseType,
  responseStatusArray,
  responseTypeArray,
} from '../../../shared/enumeration/ResponseStatus'
import { ISelectValue } from '../../../shared/shared-interfaces'
import SModal from '../../shared/Modal/SModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { AppDispatch } from '../../../store'
import { ToastSuccess } from '../../shared/toast/Toast'
import { fetching, resetEntity } from './configManagement.reducer'
import { createEntity, updateEntity } from './configManagement.api'
import CIcon from '@coreui/icons-react'
import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import { handleUploadImage } from '@/shared/utils/ultils'
import { cisCameraPlus } from '@coreui/icons-pro'
import { IConfig, INewConfig } from '@/shared/model/config.model'

interface IConfigUpdateProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  configObj?: IConfig
}

const ConfigUpdate = (props: IConfigUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const { updateEntitySuccess } = initialState
  const { visible, setVisible, configObj } = props
  const handleOnClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false)
      ToastSuccess(
        configObj ? 'Cập nhậtthành công' : 'Tạo thành công',
      )
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  const initialValues: INewConfig = {
    key: '',
    name: '',
    image: '',
    order: 0,
    status: ResponseStatus.ACTIVE,
    fileImage: undefined,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Không được để trống'),
  })

  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === optionValue)
  }

  const handleFileUploadAvatar = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileImage', inputFiles[0])
        setFieldValue('image', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleFileUploadQr = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileQr', inputFiles[0])
        setFieldValue('qr', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleRemappingImageValue = async (value: INewConfig) => {
    if (value.fileImage) {
      const avatarUrlPromises = await handleUploadImage(value.fileImage)
      value.image = avatarUrlPromises
    }
    return value
  }

  console.log(configObj)

  return (
    <SModal
      visible={visible}
      onClose={handleOnClose}
      backdrop="static"
      className="custom-modal"
      size="lg"
    >
      <Formik
        initialValues={configObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (value) => {
          const values = await handleRemappingImageValue(value)
          dispatch(fetching())
          if (configObj) {
            dispatch(updateEntity(values as IConfig))
          } else {
            dispatch(createEntity(values))
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>
                {configObj ? 'Chỉnh sửa' : 'Tạo'}
              </CModalHeader>
              <CModalBody>
                <CRow className="g-4">
                <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Key
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="key"
                      autoComplete="off"
                      value={values.key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.key && touched.key}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.key && touched.key ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.key}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên liên hệ
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.name && touched.name}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.name && touched.name ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.name}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Số thứ tụ
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="order"
                      autoComplete="off"
                      value={values.order}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.order && touched.order}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.order && touched.order ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.order}
                    </CFormFeedback>
                  </CCol>
                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Mô tả
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="description"
                      autoComplete="off"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.name && touched.description}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.description && touched.description
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.description}
                    </CFormFeedback>
                  </CCol>
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Link
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="link"
                      autoComplete="off"
                      value={values.link}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.link && touched.link}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.link && touched.link
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.link}
                    </CFormFeedback>
                  </CCol>
                  <CCol xs={12}>
                    <CRow>
                      <CCol xs={12}>
                        <CFormLabel className="fw-bold text-sm mb-0 required-field">
                          Ảnh
                        </CFormLabel>
                        <div
                          className="d-flex justify-content-center"
                          style={{ background: '#f6f6f6' }}
                        >
                          <div className="profileImageContainer">
                            <div
                              className="position-relative"
                              style={{ height: 200 }}
                            >
                              {values.image ? (
                                <CImage
                                  color="secondary"
                                  rounded
                                  className="image-xxl object-fit-cover"
                                  src={values.image}
                                  style={{ width: '100%', height: '100%' }}
                                />
                              ) : (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  style={{ width: '100%', height: '100%' }}
                                >
                                  <CIcon
                                    icon={cisCameraPlus}
                                    className="mb-3"
                                    style={{ width: '33px', height: '33px' }}
                                  />
                                  <p>Chọn ảnh</p>
                                </div>
                              )}
                            </div>
                            <CFormLabel
                              htmlFor="custom-task-file-input"
                              className={`p-image`}
                            >
                              {values.image ? (
                                <CIcon
                                  icon={cisCameraPlus}
                                  color="secondary"
                                  className="upload-button"
                                />
                              ) : (
                                <div className="add-image"></div>
                              )}
                              <input
                                id="custom-task-file-input"
                                className="file-upload"
                                type="file"
                                onClick={(e) => (e.currentTarget.value = '')}
                                // name="imageUrl"
                                onChange={(e) =>
                                  handleFileUploadAvatar(e, setFieldValue)
                                }
                                // accept={inputFileTypesAccept}
                                accept="image/*"
                              />
                            </CFormLabel>
                          </div>
                        </div>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.image && touched.image
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.image}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Kiểu cấu hình
                    </CFormLabel>
                    <CFormSelect
                      className="form-height-44"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.type && touched.type}
                    >
                      {responseTypeArray.map((item, index) => (
                        <option key={index} value={item}>
                          {mapResponseType[item]}
                        </option>
                      ))}
                    </CFormSelect>
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.type && touched.type ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.type}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Trạng thái
                    </CFormLabel>
                    <CFormSelect
                      className="form-height-44"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.status && touched.status}
                    >
                      {responseStatusArray.map((item, index) => (
                        <option key={index} value={item}>
                          {mapResponseStatus[item]}
                        </option>
                      ))}
                    </CFormSelect>
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.status && touched.status ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.status}
                    </CFormFeedback>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm()
                    setVisible(false)
                  }}
                >
                  Huỷ
                </CButton>
                <CButton
                  className="btn-custom minw-120 primary-500"
                  type="submit"
                >
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  )
}

export default ConfigUpdate
