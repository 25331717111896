// export const SERVER_API_URL = 'http://localhost:8070/api/';
// // export const UPLOAD_API_URL = '';
// export const UPLOAD_API_URL = 'http://localhost:8070/api/';
// export const SYNCFUSION_COMMUNITY_KEY =
//   'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
// export const SOCKET_URL = 'ws://localhost:8070';

// PROD

export const SERVER_API_URL = 'https://api1.77vipbet.casino/api/';
// export const UPLOAD_API_URL = '';
export const UPLOAD_API_URL = 'https://api1.77vipbet.casino/api/';
export const SYNCFUSION_COMMUNITY_KEY =
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
export const SOCKET_URL = 'https://api1.77vipbet.casino';