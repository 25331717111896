import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal'
import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import {
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'

import { createIndexes, getCurrrentDraw } from '../../../shared/utils/ultils'
import { AppDispatch } from '../../../store'
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter'

import {
  fetching,
  setFilterState,
  historyOrderSelectors,
} from './historyOrderManagement.reducer'
import dayjs from 'dayjs'
import { IHistoryOrder } from '@/shared/model/history-order.model'
import { getEntities } from './historyOrderManagement.api'
import { SystemRole } from '@/shared/enumeration/role'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(timezone);

const columns = [
  {
    key: 'id',
    label: 'Mã đơn hàng',
    sorter: false,
  },
  {
    key: 'userId',
    label: 'ID người chơi',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Trạng thái',
    sorter: false,
  },
  {
    key: 'refNo',
    label: 'Mã hóa đơn',
    sorter: false,
  },
  {
    key: 'stake',
    label: 'Tiền cược',
    sorter: false,
  },
  {
    key: 'winLost',
    label: 'Lãi lỗ',
    sorter: false,
  },
  {
    key: 'orderTime',
    label: 'Thời gian đặt',
    sorter: false,
  },
]

interface IIndexHistoryOrder extends IHistoryOrder {
  index: number
}

const HistoryOrderManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector(
    (state: RootState) => state.historyOrderReducer,
  )

  const { user } = useSelector((state: RootState) => state.authentication)

  const {
    filterState,
    totalItems,
    totalPages,
    deleteEntitySuccess,
  } = initialState
  const [createHistoryOrder, setCreateHistoryOrder] = useState<boolean>(false)
  const [
    updateHistoryOrder,
    setUpdateHistoryOrder,
  ] = useState<IHistoryOrder | null>(null)
  const [currentDraw, setCurrentDraw] = useState<IHistoryOrder | null>(null)
  const [loadParent, setLoadParent] = useState<Boolean>(false)

  const handleCreateHistoryOrder = () => {
    setCreateHistoryOrder(true)
  }

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0)
      dispatch(fetching())
      if (user?.role === SystemRole.ADMIN) {
        dispatch(setFilterState({ ...filterState, page: page }))
      } else {
        dispatch(
          getEntities({ ...filterState, page: page, agentId: user?.username }),
        )
      }
    }
  }

  const historys = useSelector(historyOrderSelectors.selectAll)
  const indexedHistoryOrder = createIndexes(historys, filterState)

  useEffect(() => {
    dispatch(fetching())
    if (user?.role === SystemRole.ADMIN) {
      dispatch(getEntities({ ...filterState }))
    } else {
      dispatch(getEntities({ ...filterState, agentId: user?.username }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), user])


  const [chosenDelete, setChosenDelete] = useState<IHistoryOrder | null>(null)

  const handleCancelDelete = () => {
    setChosenDelete(null)
  }
  const [keyword, setKeyword] = useState<string>('')

  const onSearch = () => {
    if (user?.role === SystemRole.ADMIN) {
      dispatch(getEntities({ ...filterState, keyword }))
    } else {
      dispatch(
        getEntities({ ...filterState, keyword, agentId: user?.username }),
      )
    }
  }
  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className="d-flex">
            <CFormInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ID hoặc Tên đăng nhập"
            />
            <CButton
              style={{ height: 42, whiteSpace: 'nowrap' }}
              className="ms-2 btn-custom btn-sm text-white"
              onClick={onSearch}
            >
              Tìm kiếm
            </CButton>
          </div>
        </div>
        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedHistoryOrder}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              userId: ({ index, ...value }: IIndexHistoryOrder) => {
                return (
                  <td>
                    <div className={'d-flex align-items-center flex-nowrap'}>
                      {value?.userId} - {value?.username}
                    </div>
                  </td>
                )
              },
              // status: ({ index, ...value }: IIndexHistoryOrder) => {
              //   return (
              //     <td>
              //       <div
              //         className={
              //           'd-flex align-items-center flex-nowrap text-'
              //           // mapResponseStatusToColorDraw[value?.status ?? 'open']
              //         }
              //       >
              //         {/* {mapResponseStatusDraw[value?.status ?? 'open']} */}
              //       </div>
              //     </td>
              //   )
              // },
              voted: ({ index, ...value }: IIndexHistoryOrder) => {
                return (
                  <td>
                    <div className={'d-flex align-items-center flex-nowrap'}>
                      {/* {mapResponseVotedHistoryOrder[value.voted]} */}
                    </div>
                  </td>
                )
              },
              orderTime: ({ index, ...value }: IIndexHistoryOrder) => {
                return (
                  <td>
                    <div className={'d-flex align-items-center flex-nowrap'}>
                      {dayjs(value.createdDate).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                  </td>
                )
              },
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
    </>
  )
}

export default HistoryOrderManagement
